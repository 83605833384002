
import { defineComponent, onMounted, ref, watch } from "vue";
import AnimalCard from "@/presentation/components/cards/AnimalCard.vue";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  ROUTE_PAGES,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { AnimalStatusEnum } from "@/domain/enum/AnimalStatus";
import { AnimalListModel } from "@/domain/animal/animalList/model/AnimalListModel";
import { useRouter } from "vue-router";
import { BarnyardListModel } from "@/domain/barnyard/barnyardList/model/BarnyardListModel";

export default defineComponent({
  name: COMPONENT_NAME.ANIMAL_LIST,
  components: {
    AnimalCard,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const originalAnimalList = ref<AnimalListModel[]>([]);
    const swalNotification = new SwalNotification();
    const isLoading = ref<boolean>(false);

    const barnyardController = store.state.ControllersModule.barnyardController;
    const barnyardList = ref<BarnyardListModel[]>([]);

    const animalController = store.state.ControllersModule.animalController;
    if (!animalController) {
      throw new Error("AnimalController is not available in Vuex store.");
    }

    const currentPage = ref(1);
    const pageSize = ref(5);
    const totalItems = ref(0);
    const pageCount = ref(1);

    watch(
      () => store.getters.newAnimal,
      () => {
        if (store.getters.newAnimal) getBarnyardList(1);
      }
    );

    const getBarnyardList = async (page) => {
      barnyardList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const barnyardListModel: BarnyardListModel = {
        barnyard: {},
        page: page,
        pageSize: pageSize.value,
      };

      isLoading.value = true;

      barnyardController
        .barnyardList(barnyardListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach(async (barnyard) => {
              totalItems.value = barnyard.pagination.total;
              currentPage.value = barnyard.pagination.page;
              pageCount.value = barnyard.pagination.pageCount;
              const animalListModel: AnimalListModel = {
                animal: {
                  profilePhotoUrl: "media/animal/animal.jpg",
                },
                page: 1,
                pageSize: 10,
                barnyardId: barnyard.barnyard.id,
              };

              const animalResponse = await animalController.animalList(
                animalListModel
              );

              if (animalResponse) {
                barnyard.barnyard.animalList = animalResponse.getValue();
              } else {
                swalNotification.error(
            response.error.cause.cause + t(SWAL_MESSAGES.ERROR_CODE) + response.error.code + response.error.cause.code,
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
          );
              }
              barnyardList.value.push(barnyard);
            });
          } else {
            swalNotification.error(
            response.error.cause.cause + t(SWAL_MESSAGES.ERROR_CODE) + response.error.code + response.error.cause.code,
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
          );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.ANIMAL_LIST));
      getBarnyardList(1);
    });

    const routeToAnimalInfo = (animal) => {
      router.push({
        name: ROUTE_PAGES.ANIMAL_INFO,
        params: { id: animal.animal.id },
      });
    };

    const routeToBarnyardsAnimalList = (barnyardId) => {
      router.push({
        name: ROUTE_PAGES.BARNYARD_ANIMALS,
        params: { id: barnyardId },
      });
    };

    const routeToCreateAnimal = (barnyardId) => {
      store.dispatch("selectedBarnyardId", barnyardId);
      router.push({
        name: ROUTE_PAGES.CREATE_ANIMAL,
      });
    };

    return {
      PAGE_TITLE_PATH,
      currentPage,
      pageCount,
      barnyardList,
      originalAnimalList,
      AnimalStatusEnum,
      pageSize,
      isLoading,
      totalItems,
      routeToBarnyardsAnimalList,
      routeToAnimalInfo,
      routeToCreateAnimal,
      getBarnyardList,
    };
  },
});
