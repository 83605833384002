<template>
    <!--begin::Card-->
    <div class="card h-100" :class="cardClasses">
      <div class="card-header flex-nowrap border-0 pt-9">
        <div class="card-title m-0">
          <div class="symbol symbol-100px symbol-circle w-45px me-5">
            <img :src="icon" class="p-3" style=""/>
          </div>
  
          <a class="fs-4 fw-bold text-hover-primary text-gray-600 m-0">
            {{ title }}
          </a>
        </div>
  
      </div>
  
      <!--begin::Card body-->
      <div class="card-body d-flex flex-column px-9 pt-6 pb-8">
        <!--begin::Heading-->
        <div class="fw-bolder mb-3">
          <a href="#" style="color: black; font-size: 20px;"> {{ content }} </a>
        </div>
        <!--end::Heading-->
  
        <!--begin::Stats-->
        <div class="d-flex align-items-center flex-wrap mb-5 mt-auto fs-6">
          <span :class="`svg-icon-${color}`" class="svg-icon svg-icon-3 me-1">
            <inline-svg :src="`media/icons/duotune/Navigation/${arrow}.svg`" />
          </span>
  
          <!--begin::Number-->
          <div :class="`text-${color}`" class="fw-bolder me-2" style="font-size: 10px;">
            {{ footerData }}
          </div>
          <!--end::Number-->
  
          <!--begin::Label-->
          <div class="fw-bold text-gray-400">
            {{ footerText }}
          </div>
          <!--end::Label-->
        </div>
        <!--end::Stats-->
  
        <!--begin::Indicator-->
        <div class="d-flex align-items-center fw-bold">
          <span class="badge bg-light text-gray-700 px-3 py-2 me-2">
            {{ indicatorValue }}
          </span>
  
          <span class="text-gray-400 fs-7">{{ indicatorLabel }}</span>
        </div>
        <!--end::Indicator-->
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Card-->
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  
  export default defineComponent({
    name: "card-2",
    props: {
      cardClasses: String,
  
      icon: String,
  
      title: String,
  
      content: String,
  
      color: String,
  
      arrow: String,
  
      footerData: String,
  
      footerText: String,
  
      indicatorValue: String,
  
      indicatorLabel: String,
    },
  });
  </script>
  