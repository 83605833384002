
  import { defineComponent } from "vue";
  
  export default defineComponent({
    name: "card-2",
    props: {
      cardClasses: String,
  
      icon: String,
  
      title: String,
  
      content: String,
  
      color: String,
  
      arrow: String,
  
      footerData: String,
  
      footerText: String,
  
      indicatorValue: String,
  
      indicatorLabel: String,
    },
  });
  