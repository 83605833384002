<template>
  <div
    v-if="isLoading"
    style="
      height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <div class="text-center px-5 pb-7">
      <img
        alt="Logo"
        class="max-h-35px"
        src="/media/loading_icon.gif"
        style="width: 40px"
      />
    </div>
  </div>
  <div v-else-if="barnyardList.length > 0">
    <div v-for="barnyard in barnyardList" :key="barnyard.barnyard.id">
      <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fw-bold">
          {{ barnyard.barnyard.name }} 
        </h2>

        <div class="d-flex align-items-center py-1">
          <!--begin::Button-->
          <a  
          @click="routeToCreateAnimal(barnyard.barnyard.id)"
            class="btn btn-sm me-2" style="background-color:blueviolet; color:white"
            id="kt_toolbar_primary_button">
            {{ $t(PAGE_TITLE_PATH.ANIMAL_CREATE) }}
          </a>
          <a
            @click="routeToBarnyardsAnimalList(barnyard.barnyard.id)"
            class="btn btn-sm btn-primary"
            id="kt_toolbar_primary_button"
            v-if="barnyard.barnyard.animalList && barnyard.barnyard.animalList.length > 0"
          >
            {{ $t("menuPages.animalList.seeAll") }}
          </a>
          <!--end::Button-->
        </div>
      </div>
      <div class="row g-6 g-xl-9" v-if="barnyard.barnyard.animalList">
        <div
          v-if="barnyard.barnyard.animalList.length > 0"
          class="col-sm-6 col-xl-2"
          v-for="animal in barnyard.barnyard.animalList"
          :key="animal.animal.id"
        >
          <div class="card h-100">
            <AnimalCard
              @click="routeToAnimalInfo(animal)"
              card-classes="h-100"
              :content="animal.animal.name"
              :icon="animal.animal.profilePhotoUrl"
              color="success"
              arrow="Up-right"
              footer-text=""
              :indicatorLabel="animal.animal.animalBreed.data.attributes.name"
            ></AnimalCard>
          </div>
        </div>
        <div v-else class="card-px">
          <!--begin::Description-->
          <p class="text-gray-400 fs-5 fw-bold">
            <span>{{ $t("menuPages.animalList.zeroCount") }}</span>
          </p>
          <!--end::Description-->
        </div>
      </div>

      
    </div>

      <!--begin::Pagination-->
  <div
    class="d-flex flex-stack flex-wrap pt-10"
    v-if="barnyardList.length != 0 && barnyardList.length >= 5"
  >
    <!--begin::Controls-->
    <div class="d-flex flex-wrap my-1">
      <!--begin::Select wrapper-->
      <div class="m-0">
        <!--begin::Select-->
        <select
          name="status"
          data-control="select2"
          data-hide-search="true"
          class="form-select form-select-white form-select-sm fw-bolder w-125px"
          v-model="pageSize"
          @change="getBarnyardList(1)"
        >
          <option value="5" selected>5</option>
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </select>
        <span>{{ $t("common.table.pagination.desc2") }}</span>

        <!--end::Select-->
      </div>
      <!--end::Select wrapper-->
    </div>
    <!--end::Controls-->

    <div class="fs-6 fw-bold text-gray-700">
      {{ totalItems }} {{ $t("common.table.pagination.desc") }}
      {{ (currentPage - 1) * pageSize + 1 }} -
      {{ Math.min(currentPage * pageSize, totalItems) }}
      {{ $t("common.table.pagination.desc1") }}
    </div>

    <!--begin::Pages-->
    <ul class="pagination">
      <li
        class="page-item previous"
        @click="getBarnyardList(currentPage - 1)"
        :class="{ disabled: currentPage === 1 }"
      >
        <a href="#" class="page-link"><i class="previous"></i></a>
      </li>

      <li
        v-for="page in pageCount"
        :key="page"
        class="page-item"
        :class="{ active: currentPage === page }"
      >
        <a href="#" class="page-link" @click="getBarnyardList(page)">{{
          page
        }}</a>
      </li>

      <li
        class="page-item next"
        @click="getBarnyardList(currentPage + 1)"
        :class="{ disabled: currentPage === pageCount }"
      >
        <a href="#" class="page-link"><i class="next"></i></a>
      </li>
    </ul>
    <!--end::Pages-->
  </div>
  <!--end::Pagination-->


  </div>
  <div v-else class="card-px text-center">
    <div class="text-center px-5 pb-7">
      <img
        src="/media/animal/barnyard.jpg"
        class="symbol symbol-100px symbol-lg-160px mw-100 mh-300px"
      />
    </div>

    <!--begin::Description-->
    <p class="text-gray-400 fs-5 fw-bold">
      <span>{{ $t("menuPages.barnyards.zeroBarnyard") }}</span>
    </p>
    <!--end::Description-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import AnimalCard from "@/presentation/components/cards/AnimalCard.vue";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  ROUTE_PAGES,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { AnimalStatusEnum } from "@/domain/enum/AnimalStatus";
import { AnimalListModel } from "@/domain/animal/animalList/model/AnimalListModel";
import { useRouter } from "vue-router";
import { BarnyardListModel } from "@/domain/barnyard/barnyardList/model/BarnyardListModel";

export default defineComponent({
  name: COMPONENT_NAME.ANIMAL_LIST,
  components: {
    AnimalCard,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const originalAnimalList = ref<AnimalListModel[]>([]);
    const swalNotification = new SwalNotification();
    const isLoading = ref<boolean>(false);

    const barnyardController = store.state.ControllersModule.barnyardController;
    const barnyardList = ref<BarnyardListModel[]>([]);

    const animalController = store.state.ControllersModule.animalController;
    if (!animalController) {
      throw new Error("AnimalController is not available in Vuex store.");
    }

    const currentPage = ref(1);
    const pageSize = ref(5);
    const totalItems = ref(0);
    const pageCount = ref(1);

    watch(
      () => store.getters.newAnimal,
      () => {
        if (store.getters.newAnimal) getBarnyardList(1);
      }
    );

    const getBarnyardList = async (page) => {
      barnyardList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const barnyardListModel: BarnyardListModel = {
        barnyard: {},
        page: page,
        pageSize: pageSize.value,
      };

      isLoading.value = true;

      barnyardController
        .barnyardList(barnyardListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach(async (barnyard) => {
              totalItems.value = barnyard.pagination.total;
              currentPage.value = barnyard.pagination.page;
              pageCount.value = barnyard.pagination.pageCount;
              const animalListModel: AnimalListModel = {
                animal: {
                  profilePhotoUrl: "media/animal/animal.jpg",
                },
                page: 1,
                pageSize: 10,
                barnyardId: barnyard.barnyard.id,
              };

              const animalResponse = await animalController.animalList(
                animalListModel
              );

              if (animalResponse) {
                barnyard.barnyard.animalList = animalResponse.getValue();
              } else {
                swalNotification.error(
            response.error.cause.cause + t(SWAL_MESSAGES.ERROR_CODE) + response.error.code + response.error.cause.code,
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
          );
              }
              barnyardList.value.push(barnyard);
            });
          } else {
            swalNotification.error(
            response.error.cause.cause + t(SWAL_MESSAGES.ERROR_CODE) + response.error.code + response.error.cause.code,
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
          );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.ANIMAL_LIST));
      getBarnyardList(1);
    });

    const routeToAnimalInfo = (animal) => {
      router.push({
        name: ROUTE_PAGES.ANIMAL_INFO,
        params: { id: animal.animal.id },
      });
    };

    const routeToBarnyardsAnimalList = (barnyardId) => {
      router.push({
        name: ROUTE_PAGES.BARNYARD_ANIMALS,
        params: { id: barnyardId },
      });
    };

    const routeToCreateAnimal = (barnyardId) => {
      store.dispatch("selectedBarnyardId", barnyardId);
      router.push({
        name: ROUTE_PAGES.CREATE_ANIMAL,
      });
    };

    return {
      PAGE_TITLE_PATH,
      currentPage,
      pageCount,
      barnyardList,
      originalAnimalList,
      AnimalStatusEnum,
      pageSize,
      isLoading,
      totalItems,
      routeToBarnyardsAnimalList,
      routeToAnimalInfo,
      routeToCreateAnimal,
      getBarnyardList,
    };
  },
});
</script>

<style lang="scss">
table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

table.dataTable > thead {
  th.sorting {
    position: relative;
  }
  .sorting:after {
    position: absolute;
  }
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: none;
  border-radius: 0.475rem;
  font-weight: 500;
  font-size: 1.075rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #009ef7;
}
</style>
@/domain/enum/AnimalStatus
